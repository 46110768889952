import { Container } from 'react-bootstrap';

function Footer(){

    return (
        <Container>
            <hr />
            <p className="text-center cacheHaptik text-muted fs-foot">&copy; CACHE HOLDINGS LTD {(new Date().getFullYear())}</p>
            <p className="text-center cacheHaptik text-muted fs-foot">Company&nbsp;No:&nbsp;13841639</p>
            <p className="text-center cacheHaptik text-muted fs-foot">
                <a className="text-muted mx-1" href="mailto:support@cache.city">Contact&nbsp;Us</a>
                <a className="text-muted mx-1" href="https://www.linkedin.com/company/cachemoney/">LinkedIn</a>
                <a className="text-muted mx-1" href="https://instagram.com/cachemoneyclick_">Instagram</a>
                <a className="text-muted mx-1" href="https://twitter.com/cachemoneytweet">Twitter</a>
            </p>
            <p className="text-center cacheHaptik text-muted fs-foot">
            <a className="text-muted mx-1" href="https://cache.city/terms">Terms and Conditions</a>
            <a className="text-muted mx-1" href="https://cache.city/privacy">Privacy Policy</a>
            </p>
        </Container>
    )
}

export default Footer;