import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import logo from '../assets/CACHE.svg';
import React, { useState } from 'react';

//import { useContext } from 'react';
//import { AuthContext } from './contexts/AuthContext';

function NavigationBar(){
    //const { logout, isAuthenticated, user } = useMoralis();
    //const { logout, isAuthenticated } = useMoralis();
    const [isAuthenticated, setIsAuthenticated] = useState();
    //useContext(AuthContext);


    const logout = (async () => {
        console.log("logout")
    });

    return(
        <Navbar bg="light" expand="lg" className="cache-font cache-nav py-0">
            <Container fluid>
            <Navbar.Brand className="py-0">
                <img
                    src={logo}
                    width="70"
                    height="30"
                    className="d-inline-block align-top"
                    alt="CACHE Logo"
                />
            </Navbar.Brand>
            {/*<Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="justify-content-end ms-auto">
                    <Nav.Link className="ms-3 py-nav">FAQ</Nav.Link>
                {isAuthenticated ? 
                    <NavDropdown align="end" title="MY CACHE" id="basic-nav-dropdown" className="ms-3">
                        {/*<NavDropdown.Item disabled className="custom-disabled cache-haptik">Welcome {user?.get("ethAddress")}</NavDropdown.Item>
                        <NavDropdown.Item disabled className="custom-disabled cache-haptik">Balance: <NativeBalance/></NavDropdown.Item>}
                        <NavDropdown.Item disabled className="custom-disabled">Coming Soon!</NavDropdown.Item>
                        <NavDropdown.Item className="custom-disabled" onClick={logout}>Sign Out</NavDropdown.Item>
                    </NavDropdown>
                    :
                    <Nav.Link className="ms-3 py-nav">Signup/Login</Nav.Link>
                }
            </Nav>
            </Navbar.Collapse>*/}
            </Container>
        </Navbar>
    );
}

export default NavigationBar;