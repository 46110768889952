import { useEffect, useState } from 'react';
import { Container, Spinner } from 'react-bootstrap';
import NavigationBar from './components/NavigationBar';
import Footer from './components/Footer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import OwnsMix from './pages/OwnsMix';
import { AuthContext } from './components/contexts/AuthContext'

function App() {
  const [ isAuthenticated, setIsAuthenticated ] = useState(false);
  const [ isOwner, setIsOwner ] = useState(false);
  const [ ethersProvider, setEthersProvider ] = useState();

  return (
    <AuthContext.Provider value={{isAuthenticated: [isAuthenticated, setIsAuthenticated], isOwner: [isOwner, setIsOwner], ethersProvider: [ethersProvider, setEthersProvider]}}>
    <Container fluid className="p-0 m-0">
        <Router>
          <NavigationBar />
          <Routes>
            <Route path="/mixes">
                <Route path="dj-vadim" element={<OwnsMix mixName="Vadim" />}></Route>
                <Route path="dj-yoda" element={<OwnsMix mixName="Yoda" />}></Route>
                <Route path="dj-mk" element={<OwnsMix mixName="mk" />}></Route>
                <Route path="shortee-blitz" element={<OwnsMix mixName="shortee" />}></Route>
                <Route path="loai" element={<OwnsMix mixName="loai" />}></Route>
            </Route>
          </Routes>
          <Footer />
        </Router>
    </Container>
    </AuthContext.Provider>
  );
}

export default App;
