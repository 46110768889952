import { Container, Row, Col, Button, Image } from "react-bootstrap";
import {
    VadimHeader,
    mkHeader,
    shorteeHeader,
    YodaHeader,
    loaiHeader,
    VadimVID,
    YodaVID,
    mkVID,
    shorteeVID,
    loaiVID,
    MixCopy } from '../assets/mixes';
import React, { useEffect, useState, useContext } from 'react'
import { useMediaQuery } from 'react-responsive';
import Auth from '../components/Auth';
import { AuthContext } from '../components/contexts/AuthContext';
import { initializeAlchemy, getOwnersForNft, Network } from '@alch/alchemy-sdk';


function OwnsMix(props){
    const { isAuthenticated, ethersProvider, isOwner } = useContext(AuthContext);
    const [ isAuthenticatedValue, setIsAuthenticatedValue ] = isAuthenticated;
    const [ ethersProviderLocal, setEthersProviderLocal ] = ethersProvider;
    const [ isOwnerValue, setIsOwnerValue ] = isOwner;

    const [ address, setAddress ] = useState();
    const [ nftAddress, setNftAddress ] = useState();
    const [ nftId, setNftId ] = useState();
    const [ isPolygon, setIsPolygon ] = useState(false);

    const [ data, setData ] = useState();
    const [ vid, setVid ] = useState();
    const [ header, setHeader ] = useState();
    const [ mixURL, setMixURL ] = useState();
    const [ openSeaURL, setOpenSeaURL ] = useState();
    const isMobile = useMediaQuery({ query: '(max-width: 992px)' });

    useEffect(() => {
        switch(props.mixName){
            default : break;
            case 'Vadim':
                setVid(VadimVID);
                setHeader(VadimHeader);
                setData(MixCopy.Vadim);
                setNftId('0');
                setNftAddress('0x84b84067c38e9eed1fd143852aa0cd06fbf44b94');
                setMixURL('https://www.mixcloud.com/widget/iframe/?hide_cover=1&hide_artwork=1&feed=%2Fcachemoneyclick%2Fz8zezespura37w9uwlp6ruxupupeyaha%2F');
                setOpenSeaURL('https://opensea.io/assets/ethereum/0x84b84067c38e9eed1fd143852aa0cd06fbf44b94/0');
                break;
            case 'Yoda':
                setVid(YodaVID);
                setHeader(YodaHeader);
                setData(MixCopy.Yoda);
                setNftId('0');
                setNftAddress('0xd8855c72a04e574638b6ea887bd30aa6ea04bd9b');
                setMixURL('https://www.mixcloud.com/widget/iframe/?hide_cover=1&hide_artwork=1&feed=%2Fcachemoneyclick%2Fnvfijxhabwmrlqoafydxuzstovymgj%2F');
                setOpenSeaURL('https://opensea.io/assets/ethereum/0xd8855c72a04e574638b6ea887bd30aa6ea04bd9b/0');
                break;
            case 'mk':
                setVid(mkVID);
                setHeader(mkHeader);
                setData(MixCopy.mk);
                break;
            case 'shortee':
                setVid(shorteeVID);
                setHeader(shorteeHeader);
                setData(MixCopy.shortee);
                break;
            case 'loai':
                setVid(loaiVID);
                setHeader(loaiHeader);
                setData(MixCopy.LOAI);
                setNftId('0');
                setIsPolygon(true);
                setNftAddress('0x6b40ce848ec3523fdfcdd04db2b4cfd594fd3e2b');
                setMixURL('https://www.mixcloud.com/widget/iframe/?hide_cover=1&hide_artwork=1&feed=%2Fcachemoneyclick%2Fbmkykobs96e7u53is9hs%2F');
                setOpenSeaURL('https://opensea.io/assets/matic/0x6b40ce848ec3523fdfcdd04db2b4cfd594fd3e2b/0');
        }
    }, [props.mixName]);

    useEffect(() => {
        async function updateUser(){
            if (!nftAddress || !nftId || !mixURL){
                setIsOwnerValue(false);
                return;
            }
            var settings = {};

            if (isPolygon){
                settings = {apiKey: process.env.REACT_APP_ALCH_KEY, network: Network.MATIC_MAINNET};
            } else {
                settings = {apiKey: process.env.REACT_APP_ALCH_KEY};
            }
            const alchemy = initializeAlchemy(settings);
            const addr = await ethersProviderLocal.getSigner().getAddress();
            setAddress(addr);
            const owners = await getOwnersForNft(alchemy, nftAddress, nftId);
            if (owners.owners.includes(addr.toLowerCase())){
                setIsOwnerValue(true);
            } else {
                setIsOwnerValue(false);
            }
        }
        if (ethersProviderLocal){
            updateUser();
        }
    }, [ethersProviderLocal, isPolygon]);


    return (
        <Container fluid className="p-0 m-0 w-100">
            <Image fluid className="img-full-wide" src={header} />
            <Auth />
            {!isMobile && isAuthenticatedValue && isOwnerValue ?
            <Container className="mt-5">
                <Row>
                    <Col sm={7} className="px-5 pt-5">
                            <video playsInline className="mix-vid mb-4" autoPlay loop src={vid} muted />
                            <p className="h6 mb-2 text-uppercase">Play this mix</p>
                            <iframe width="100%" height="120" src={mixURL} frameBorder="0" title="CACHE RULES MIX"></iframe>
                            {data?.utilities[0] && <p className="h6 mt-4 text-uppercase">Utilities</p>}
                            {data?.utilities.map((item, key) => {
                                return <p key={key} className="mb-0 cache-haptik">{item}</p>
                            })}
                            {data?.tracklist[0] && <p className="h6 text-uppercase mt-4">Tracklisting</p>}
                            {data?.tracklist.map((item, key) => {
                                return <p key={key} className="mb-0 cache-haptik text-capitalize">{item}</p>
                            })}
                    </Col>
                    <Col sm={5} className="px-5 pt-5">
                            <p className="mt-3 h5 text-uppercase">{data?.artist}</p>
                            <p className="h6 mb-0 text-uppercase mt-2">{data?.title}</p>
                            <p className="h6 mb-0 text-uppercase mt-1">{data?.amount}</p>
                            <p className="h6 mb-0 text-uppercase mt-4">{data?.description[0]}</p>
                            <p className="mt-1 cache-haptik">{data?.description[1]}</p>
                            <p className="h6 mb-0 text-uppercase mt-4">{data?.description[2]}</p>
                            <p className="mt-1 cache-haptik">{data?.description[3]}</p>
                            <p className="h6 mb-0 text-uppercase mt-4">{data?.description[4]}</p>
                            <p className="mt-1 cache-haptik">{data?.description[5]}</p>
                            <a href={openSeaURL}>
                                <Button variant="dark" className="mt-4 text-uppercase w-100 border-0 rounded-0 fs-7 share-btn">View on OpenSea</Button>
                            </a>
                            <a href="https://cache.city/FAQs">
                                <Button className="mt-2 button-link p-0 fs-8">Read our FAQs</Button>
                            </a>
                            <a href="https://cache.city/Terms">
                                <Button className="mt-2 ms-2 button-link p-0 fs-8">Read Our T&Cs</Button>
                            </a>
                    </Col>
                </Row>
            </Container>
            : isAuthenticatedValue && isOwnerValue && isMobile ?
            <Container className="mt-3">
                <Row>
                    <Col className="mt-2">
                            <video playsInline className="mix-vid mb-2" autoPlay loop src={vid} muted />

                            <p className="mt-3 h5 text-uppercase">{data?.artist}</p>
                            <p className="h6 mb-0 text-uppercase mt-2">{data?.title}</p>
                            <p className="h6 mb-0 text-uppercase mt-2">{data?.amount}</p>
                            <p className="h6 mb-0 text-uppercase mt-3">{data?.description[0]}</p>
                            <p className="mt-1 cache-haptik">{data?.description[1]}</p>
                            <p className="h6 mb-0 text-uppercase mt-2">{data?.description[2]}</p>
                            <p className="mt-1 cache-haptik">{data?.description[3]}</p>
                            <p className="h6 mb-0 text-uppercase mt-2">{data?.description[4]}</p>
                            <p className="mt-1 cache-haptik">{data?.description[5]}</p>

                            <p className="h6 mt-3 mb-2 text-uppercase">Play the mix</p>
                            <iframe width="100%" height="120" src={mixURL} frameBorder="0" title="CACHE MIX"></iframe>
                            {data?.tracklist[0] && <p className="h6 text-uppercase mt-4">Tracklisting</p>}
                            {data?.tracklist.map((item, key) => {
                                return <p key={key} className="mb-0 cache-haptik text-capitalize">{item}</p>
                            })}
                            {data?.utilities[0] && <p className="h6 mb-2 text-uppercase">Utilities</p>}
                            {data?.utilities.map((item, key) => {
                                return <p key={key} className="mb-0 cache-haptik">{item}</p>
                            })}
                            <a href={openSeaURL}>
                                <Button variant="dark" className="mt-4 text-uppercase w-100 border-0 rounded-0 fs-7 share-btn">View on OpenSea</Button>
                            </a>
                            <a href="https://cache.city/FAQs">
                                <Button className="mt-2 button-link p-0 fs-8">Read our FAQs</Button>
                            </a>
                            <a href="https://cache.city/Terms">
                                <Button className="mt-2 ms-2 button-link p-0 fs-8">Read Our T&Cs</Button>
                            </a>
                    </Col>
                </Row>
            </Container>
            : isAuthenticatedValue && !isOwnerValue &&
            <Container className="my-5">
                <Row>
                    <Col className="my-5 justify-content-center text-center">
                        <p className="mt-3 h5 text-uppercase text-center">Unfortunately, you do not own this NFT! If you'd like to make an offer on any of our NFTs, please visit <a href="https://cache.city">our website.</a></p>
                    </Col>
                </Row>
          </Container>
        }
        </Container>
    );
}

export default OwnsMix;