import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import Authereum from "authereum";
import { ethers, providers } from "ethers";
import { useState, useEffect, useContext } from 'react';
import { AuthContext } from './contexts/AuthContext';
import { Container, Row, Col, Button } from 'react-bootstrap';

export default function Auth(props) {

    const [web3Modal, setWeb3Modal] = useState(null);
    const { isAuthenticated, ethersProvider } = useContext(AuthContext);
    const [ isAuthenticatedValue, setIsAuthenticatedValue ] = isAuthenticated;
    const [ ethersProviderLocal, setEthersProviderLocal ] = ethersProvider;
  
    useEffect(() => {
        const providerOptions = {
            injected: {
              package: null
            },
            walletconnect: {
              package: WalletConnectProvider, 
              options: {
                infuraId: process.env.REACT_APP_INFURA_KEY
              }
            },
            walletlink: {
              package: CoinbaseWalletSDK, 
              options: {
                appName: "CACHE", 
                infuraId: process.env.REACT_APP_INFURA_KEY 
              }
            },
            authereum: {
              package: Authereum // required
            },

        };

        const newWeb3Modal = new Web3Modal({
            cacheProvider: true, // very important
            network: "mainnet",
            providerOptions,
        });

        setWeb3Modal(newWeb3Modal);

    }, []);
  
    useEffect(() => {
      // connect automatically and without a popup if user is already connected
      if(web3Modal && web3Modal.cachedProvider){
        connectWallet()
      }
    }, [web3Modal])
  
    async function connectWallet() {
      try {
        const provider = await web3Modal.connect();
        addListeners(provider);
        const ethersProvider = new providers.Web3Provider(provider);
        setIsAuthenticatedValue(true);
        setEthersProviderLocal(ethersProvider);
      } catch (e) {
        console.error(e);
        return;
      }
    }

    async function disconnectWallet() {
      if (web3Modal){
        web3Modal.clearCachedProvider();
        setIsAuthenticatedValue(false);
      }
    }
  
    async function addListeners(web3ModalProvider) {
  
      web3ModalProvider.on("accountsChanged", (accounts) => {
        window.location.reload()
      });
      
      // Subscribe to chainId change
      web3ModalProvider.on("chainChanged", (chainId) => {
        window.location.reload()
      });

      // Subscribe to provider connection
      web3ModalProvider.on("connect", (chainId) => {
        window.location.reload()
      });

      // Subscribe to provider disconnection
      web3ModalProvider.on("disconnect", (code, message) => {
        disconnectWallet();
        window.location.reload();
      });
    }
  
  
    return (
      <>
        {!isAuthenticatedValue &&
          <Container className="my-5">
                <Row>
                    <Col className="my-5 justify-content-center text-center">
                            <p className="mt-3 h5 text-uppercase text-center">Welcome! Please connect your wallet to access your CACHE experience.</p>
                            <Button variant="dark" onClick={connectWallet} className="mt-4 text-uppercase border-0 rounded-0 fs-7 share-btn mx-auto">Connect your wallet</Button>
                    </Col>
                </Row>
          </Container>
        }
      </>
    )
  }